.header-logo {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.section-notice-wrap {
  position: relative;
  height: calc(var(--vh, 7px) * 80);
  background: url("../../public/images/landing-bg.png") no-repeat center;
  background-size: cover;
}

.h-inner-screen {
  height: calc(var(--vh, 7px) * 100);
}

.section-notice-text-head {
  font-weight: bold;
  color: #262626;
  font-size: 72px;
  letter-spacing: -0.04em;
}

.section-notice-btn-wrap {
  position: absolute;
  display: flex;
  bottom: 36px;
  width: 100%;
  padding: 0 20px;
  column-gap: 8px;
  justify-content: center;
}

.landing-btn {
  padding: 16px 0;
  border: 2px solid #0bb25f;
  box-shadow: 0 0 10px rgb(0 0 0 / 25%);
  border-radius: 12px;
  font-weight: bold;
  font-size: 22px;
  color: #0bb25f;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  background: white;
  width: 50%;
  cursor: pointer;
  justify-content: center;
}

.landing-list-btn {
  padding: 16px 0;
  box-shadow: 0 0 10px rgb(0 0 0 / 25%);
  border-radius: 12px;
  font-weight: bold;
  font-size: 22px;
  color: #1f2024;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  background: #f0f2f5;
  width: 50%;
  cursor: pointer;
  justify-content: center;
}

.section-second-img-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0 26px;
  margin: 24px 20px 0;
  background: #fff;
  border: 1px solid #f0f2f5;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  border-radius: 16px;
}

.section-third-wrap {
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
}

.section-third-content-wrap {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #f0f2f5;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  border-radius: 16px;
  padding: 40px 0 32px;
  text-align: center;
  margin-bottom: 24px;
}

.section-third-content-wrap:last-child {
  margin-bottom: 0;
  padding-bottom: 10px;
}

.section-fourth-wrap {
  background: url("../../public/images/landing-2.png") no-repeat center;
  background-size: cover;
  text-align: center;
  padding-top: 9%;
  height: 1096px;
}

@media (max-width: 720px) {
  .section-notice-wrap {
    height: calc(var(--vh, 7px) * 100);
  }
}
